import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { logoKB_2, menu, search, start, user_circle } from '../../../../../components/imgExport';
import '../../stylesMainNavi.css'
const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Sports',
  },
  {
    key: '2',
    label: 'Live in Game',
  },
];

const tabMargin = 30;
const tabBarGutter = (items.length - 1) * tabMargin;

const HeadTabs: React.FC = () => (
  <div className="xs:px-6 sm:px-0 xs:flex xs:justify-between sm:flex sm:items-center sm:justify-center  py-6 border-b border-b-[#202531]">
    <div className=' xs:gap-3 sm:gap-0 xs:flex xs:items-center'>
      <img src={menu} alt="" className='xs:block sm:hidden' />
      <img src={logoKB_2} alt="Logo" className='xs:w-[48px] xs:h-[48px] sm:w-[80px] sm:h-[43px]' onClick={() => { window.location.reload() }} />
    </div>
    <div className="xs:flex xs:items-center xs:gap-3 sm:hidden">
      <div className='flex items-center gap-[10px]'>
        <img src={start} alt="Crown" className='w-[32px] h-[32px]' />
        <p className="fw-[700] text-sm text-white uppercase">Bet Slip</p>
        <span className={`h-[20px] w-[20px] rounded-[50%] text-xs flex items-center justify-center bg-[#202531] text-white`}>
          0
        </span>
      </div>
      <img src={user_circle} alt="" />
    </div>
    <div className='hidden w-[173px] mr-[28px] ml-[28px]'>

      <Tabs
        className="headTab"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        tabBarGutter={tabBarGutter}
      />
      {/*  */}
    </div>
    <div className='hidden'>
      <img src={search} alt="Search" />
    </div>

  </div>
);

export default HeadTabs;
