import { soccer_2, cup, LogoWithoutText } from "../../../../components/imgExport"
interface ItemProp {
    status: number
}
export const ItemSoccer: React.FC<ItemProp> = ({
    status
}) => {
    return (
        <div className="flex w-full border-b border-b-[#202531] py-[18px]">
            <div className="flex justify-center items-center w-[255px]">
                <div className="flex flex-col items-start gap-3">
                    <span className="text-sm text-[#E4E7F1]">ID 121241</span>
                    <span className="text-sm text-[#E4E7F1]">12:12:14 04/20/2024</span>
                </div>
            </div>
            <div className="flex flex-col gap-2 items-start w-[255px] px-3">
                <div className="flex items-center gap-2">
                    <img src={soccer_2} alt="" />
                    <span className="text-xs text-[#C1C9E1] font-medium">HDP</span>
                </div>
                <div className="rounded-[4px] py-1 bg-[#212531]">
                    <span className="px-3  text-sm text-[#E4E7F1]">Name team @ + 2.97 @ 2:0</span>
                </div>
                <div className="flex gap-4">
                    <img src={cup} alt="" />
                    <div className="flex flex-col items-start gap-1">
                        <span className="text-sm text-[#E4E7F1]">Tour name</span>
                        <span className="text-xs text-[#E4E7F1]">12:12:14 04/20/2024</span>
                    </div>
                </div>
                <div className='flex items-center gap-4'>
                    <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                        <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                    </div>
                    <span className='text-[#E4E7F1] fw-[500] text-xs'>Home team name</span>
                </div>
                <div className='flex items-center gap-4'>
                    <div className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[#202531]'>
                        <img src={LogoWithoutText} alt="NYKnicks" className='w-6 h-6' />
                    </div>
                    <span className='text-[#E4E7F1] fw-[500] text-xs'>Away team name</span>
                </div>
            </div>
            <div className="flex justify-center items-center w-[255px]">
                <span className="text-sm text-[#E4E7F1]">1.92</span>
            </div>
            <div className="flex justify-center items-center w-[255px]">
                <span className="text-sm text-[#D4B962]">1000 $</span>
            </div>
            <div className="flex justify-center items-center w-[255px]">
                <span className={`text-sm text-[#33E49A] ${(status === 1 && 'text-[#33E49A]') || (status === 2 && 'text-[#3F8CFF]') || (status === 3 && 'text-[#FF4A59]')}`}>
                    {(status === 1 && 'RUNNING') || (status === 2 && 'WAITING') || (status === 3 && 'REJECT')}
                </span>
            </div>
        </div>
    )
}