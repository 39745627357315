import React, { useEffect, useState } from 'react';
import HeadTabs from './HeadTabs';
import { baketball, baketballActive, baseball, baseballActive, NCAAF, rugby, rugbyActive, soccer, soccerActice } from '../../../../../components/imgExport';
import * as homePageApi from '../../../../../api/homePage/homePageApi'
import * as loginApi from '../../../../../api/login/loginApi';
export interface itemMenu {
  id: number;
  name: string;
  icon: string;
  iconActive?: string;
  href: string;
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const itemMenu: itemMenu[] = [

  {
    id: 1,
    name: 'NBA',
    href: '/NBA',
    icon: baketball,
    iconActive: baketball,
  },
  {
    id: 2,
    name: 'NFL',
    href: '/NFL',
    icon: rugby,
    iconActive: rugby,
  },
  {
    id: 6,
    name: 'MLB',
    href: '/MLB',
    icon: baseball,
    iconActive: baseball,
  },
  {
    id: 9,
    name: 'Soccer',
    href: '/Soccer',
    icon: soccer,
    iconActive: soccer,
  },

]
interface Props {
  tournaments: itemMenu
  setTournaments: (value: itemMenu) => void
}
const HeadNavi: React.FC<Props> = ({
  tournaments,
  setTournaments
}) => {
  const [menu, setMenu] = useState<itemMenu[]>([])
  const [OTPCode, setOTPCode] = useState<string>("")
  const checkSelect = (value: string) => {
    // console.log(value);
    return value === tournaments.name ? "!text-[#D4B962] " : "text-[#C1C9E1]"
  }

  const getAuthcodeByToken = async () => {
    const ketqua = await loginApi.getAuthcodeByToken()
    // if (ketqua?.authCode) {
    //   setOTPCode(ketqua.authCode)
    // }
  }

  // viewSettings
  const viewSettings = async () => {
    const ketqua = await homePageApi.viewSettings()
    setMenu([{
      id: 0,
      name: 'Soccer',
      href: '/Soccer_V1',
      icon: soccer,
      iconActive: soccerActice,
    }])
    if (ketqua?.success) {
      if (ketqua.data.navigation.NBA === 1) {
        setMenu((data: any) => [...data, {
          id: 1,
          name: 'NBA',
          href: '/NBA',
          icon: baketball,
          iconActive: baketballActive,
        }])
      }
      if (ketqua.data.navigation.NFL === 1) {
        setMenu((data: any) => [...data, {
          id: 2,
          name: 'NFL',
          href: '/NFL',
          icon: rugby,
          iconActive: rugbyActive,
        }])
      }
      if (ketqua.data.navigation.MLB === 1) {
        setMenu((data: any) => [...data, {
          id: 6,
          name: 'MLB',
          href: '/MLB',
          icon: baseball,
          iconActive: baseballActive,
        }])
      }
      if (ketqua.data.navigation.NCAAF === 1) {
        setMenu((data: any) => [...data, {
          id: 3,
          name: 'NCAAF',
          href: '/NCAAF',
          icon: NCAAF,
          iconActive: NCAAF,
        }])
      }
      // if (ketqua.data.navigation.SOC === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 9,
      //     name: 'Soccer',
      //     href: '/Soccer',
      //     icon: Soccer
      //   }])
      // }
      // if (ketqua.data.navigation.NCAAF === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 3,
      //     name: 'NCAAF',
      //     href: '/NCAAF',
      //     icon: NCAAF
      //   }])
      // }


      // if (ketqua.data.navigation.SOC === 1) {
      //   setMenu((data: any) => [...data, {
      //     id: 10,
      //     name: 'Soccer',
      //     href: '/Soccer_V1',
      //     icon: Soccer
      //   }])
      // }

    }
  }

  useEffect(() => {
    viewSettings()
    getAuthcodeByToken()
  }, [])
  return (
    <div className="flex-col fixed top-0 left-0 z-50  bg-[#131620] xs:border-0 sm:border sm:border-[#202531]  sm:h-[100vh] sm:w-[118px]  xs:h-[80px] xs:w-full">
      <HeadTabs></HeadTabs>
      {/* <HeadRight></HeadRight> */}
      <div className=' sm:flex sm:items-center sm:flex-col sm:w-[118px] sm:bg-none sm:top-[100px] xs:fixed xs:flex xs:flex-row xs:w-full xs:bottom-0 xs:bg-[#131620] xs:border-r-0 sm:border-r sm:border-r-[#202531]'>
        {menu.map((items) => (
          <div className='py-6 border-b border-b-[#202531] w-full'>
            <div
              key={items.id}
              className=' flex justify-start items-center   py-[17px] xs:px-5 sm:px-5 md:px-5 lg:px-5 xl:px-5 xl2:px-[27px]'
              onClick={() => setTournaments(items)}
            >
              <div className='flex items-center '>
                {items.name === tournaments.name ?
                  <img src={items.iconActive} alt="" />
                  :
                  <img src={items.icon} alt="" />
                }

                <span className={`xs:text-sm lg:text-xs xl:text-xs xl2:text-base uppercase  fw-[500] pl-2 ${checkSelect(items.name)}`}>{items.name}</span>
              </div>
            </div>
          </div>

        ))}

      </div>

      <div className='text-[#222222] pr-5'>
        {OTPCode}
      </div>
    </div>
  )

}
export default HeadNavi;