import { Form, Input, Modal } from "antd"
import LockIcon from '@mui/icons-material/Lock';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './style.css'
import { useEffect, useState } from "react";
import useLoading from '../../../hook/useLoading';
import useToast from '../../../hook/useToast';
import { useDispatch } from 'react-redux';
import * as loginApi from '../../../api/login/loginApi';
import tokenService from '../../../services/token';
import { clearAlert } from "../../../store/slices/toastSlice";
import ToastGlobal from "../../ToastGlobal";
interface ModalProp {
    isOpen: boolean
    closeModal: (value: boolean) => void
}
export const ModalChangePassWord: React.FC<ModalProp> = ({
    isOpen,
    closeModal
}) => {
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [isComplexityValid, setIsComplexityValid] = useState(false);
    const [isMatch, setIsMatch] = useState(false);

    const changeUsername = async () => {
        pushLoading(true)
        const ketQua = await loginApi.changePassword(password, newPassword, repeatNewPassword)
        if (ketQua.success) {
            pushToast(ketQua?.message ? ketQua?.message : "Change password success.", "success");
            setTimeout(() => {
                tokenService.removeToken()
                window.location.href = "/login";
            }, 2000)
        } else {
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        pushLoading(false)
    }

    useEffect(() => {
        setIsLengthValid(newPassword.length >= 8 && newPassword.length <= 15);

        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
        setIsComplexityValid(regex.test(newPassword));

        setIsMatch(newPassword === repeatNewPassword);

    }, [newPassword, repeatNewPassword, password]);
    return (
        <Modal
            open={isOpen}
            onCancel={() => closeModal(false)}
            closeIcon={false}
            footer={false}
            centered
            className="custome-modal"
        >
            <div className="flex flex-col gap-6 justify-center items-center">
                <div className="flex flex-col items-center gap-1">
                    <span className="text-lg font-medium text-[#E4E7F1]">Change Your Password</span>
                    <span className="text-sm  text-[#E4E7F1]">To keep your account secure, please update your password.</span>
                </div>
                <Form form={form} className="flex flex-col gap-6 w-full">
                    <div className="flex flex-col gap-3 w-full">
                        <Form.Item name="password" className="custom-input rounded-lg border-4B5563 !m-0">
                            <Input.Password
                                prefix={<LockIcon className="text-[#C1C9E1]" />}
                                placeholder="Current password"
                                size="large"
                                className="!text-[#C1C9E1] placehoder-C1C9E1"
                                iconRender={(visible) => (visible ? <RemoveRedEyeIcon className="!text-[#C1C9E1] text-[24px]" /> : <VisibilityOffIcon className="text-[#C1C9E1 text-[24px]" />)}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item name="newPassword" className="custom-input rounded-lg border-4B5563 !m-0">
                            <Input.Password
                                prefix={<LockIcon className="text-[#C1C9E1]" />}
                                placeholder="New password"
                                size="large"
                                className="!text-[#C1C9E1] placehoder-C1C9E1"
                                iconRender={(visible) => (visible ? <RemoveRedEyeIcon className="!text-[#C1C9E1] text-[24px]" /> : <VisibilityOffIcon className="text-[#C1C9E1 text-[24px]" />)}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item name="repeatNewPassword" className="custom-input rounded-lg border-4B5563 !m-0">
                            <Input.Password
                                prefix={<LockIcon className="text-[#C1C9E1]" />}
                                placeholder="Repeat new password again"
                                size="large"
                                className="!text-[#C1C9E1] placehoder-C1C9E1"
                                iconRender={(visible) => (visible ? <RemoveRedEyeIcon className="!text-[#C1C9E1] text-[24px]" /> : <VisibilityOffIcon className="text-[#C1C9E1 text-[24px]" />)}
                                value={repeatNewPassword}
                                onChange={(e) => setRepeatNewPassword(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                    <div className="flex flex-col gap-1 w-full px-[18px]">
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className={`text-xl ${isLengthValid ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`} />
                            <span className={`text-xs ${isLengthValid ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`}>
                                Must be 8-15 characters long
                            </span>
                        </div>
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className={`text-xl ${isComplexityValid ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`} />
                            <span className={`text-xs ${isComplexityValid ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`}>
                                Include both uppercase and lowercase letters (a-z, A-Z) and numbers (0-9) and special characters
                            </span>
                        </div>
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className={`text-xl text-[#C1C9E1]`} />
                            <span className={`text-xs text-[#C1C9E1]`}>
                                Must not contain your login name, first name, or last name.
                            </span>
                        </div>
                        <div className="flex items-center gap-1">
                            <CheckCircleIcon className={`text-xl ${isMatch ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`} />
                            <span className={`text-xs ${isMatch ? 'text-[#C1C9E1]' : 'text-[#FF4A59]'}`}>
                                Both new passwords must match.
                            </span>
                        </div>
                    </div>
                </Form>

                <ToastGlobal />
                <div className="flex gap-3 w-full">
                    <button
                        className="uppercase rounded-lg border border-[#E4E7F1] bg-[#141721] text-sm text-[#E4E7F1] py-[14px] w-full font-bold"
                        onClick={() => closeModal(false)}
                    >
                        cancel
                    </button>
                    <button className="uppercase rounded-lg  bg-[#DABE64] text-sm text-[#131620] py-[14px] w-full font-bold" onClick={() => changeUsername()}>
                        creat new password
                    </button>
                </div>
                {/* <div className="flex items-center gap-[10px]">
                    <Switch defaultChecked className="bg-D4B962-checked" />
                    <span className="text-xs text-[#C1C9E1]">Log out of all devices</span>
                </div> */}
            </div>
        </Modal>
    )
}