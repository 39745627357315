import HeadNavi from "../homepage/components/MainNavi/Head/HeadNavi";
import { useState } from "react";
import { itemMenu } from "../homepage/components/MainNavi/Tabs/TabsNaviV2";
import './style.css'
import { Running } from "./Running";
import { MyBet } from "./MyBet";
import { Settled } from "./Settled";
import { Layout } from "../../routes/Layout";
export const Mybet: React.FC = () => {
    const [tournaments, setTournaments] = useState(itemMenu[0])
    const [chooseTabMyBet, setChooseTabMyBet] = useState<number>(2)
    const handelChooseTournaments = (value: itemMenu) => {
        const listParent = document.querySelectorAll(".ant-menu-parent-tournament.ant-menu-submenu-selected")
        if (listParent.length > 0) {
            listParent[0].classList.remove("ant-menu-submenu-selected")
        }

        const listChildren = document.querySelectorAll(".ant-menu-children-tournament.ant-menu-item-selected")
        if (listChildren.length > 0) {
            listChildren[0].classList.remove("ant-menu-item-selected")
        }

        setTournaments(value)
        if (value.id !== 9) {
            setTournaments(value)
            // gListGameByTournament(value.id)
            // gListAllBetMGM(value.name)
        }
        if (value.id === 0) {
            // gListOfGamesForTheHomepage()
        }
        window.location.href = value.href
        // navigator(value.href)
    }
    return (
        <Layout>
            <div className='bg-[#131620]  overflow-x-hidden !h-[100vh] pt-[34px] pr-[20px] pb-[34px] pl-[20px] text-center flex flex-col items-center relative'>
                <HeadNavi
                    tournaments={tournaments}
                    setTournaments={handelChooseTournaments}
                />
                <div className="w-full h-full flex justify-center items-center ">
                    <div className="w-[1375px] h-full flex justify-end">
                        <div className=" flex flex-col items-center justify-end  ">
                            <div className="flex items-center w-[1375px] bg-gradient-A98F34 px-6 border-r border-r-[#202530] border-l border-l-[#202530] border-t border-t-[#202530]">
                                <div className="border-r border-r-[#5D5846] flex justify-center"
                                    onClick={() => setChooseTabMyBet(1)}
                                >
                                    <span className={`text-[18px] ] font-medium py-2 pr-12 ${chooseTabMyBet === 1 ? "text-[#D4B962]" : "text-[#E4E7F1]"}`}>
                                        MY BET
                                    </span>
                                </div>
                                <div className="border-r border-r-[#5D5846] flex justify-center items-center gap-[10px] px-12"
                                    onClick={() => setChooseTabMyBet(2)}
                                >
                                    <span className={`text-[18px] ] font-medium py-2 ${chooseTabMyBet === 2 ? "text-[#D4B962]" : "text-[#E4E7F1]"}`}>
                                        Running
                                    </span>
                                    <div className={`flex justify-center items-center w-5 h-5 rounded-full  text-xs  font-medium ${chooseTabMyBet === 2 ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                        7
                                    </div>
                                </div>
                                <div className=" flex justify-center items-center gap-[10px] pl-12"
                                    onClick={() => setChooseTabMyBet(3)}
                                >
                                    <span className={`text-[18px] ] font-medium py-2 ${chooseTabMyBet === 3 ? "text-[#D4B962]" : "text-[#E4E7F1]"}`}>
                                        Settled
                                    </span>
                                    <div className={`flex justify-center items-center w-5 h-5 rounded-full  text-xs  font-medium ${chooseTabMyBet === 3 ? "bg-[#D4B962] text-[#131620]" : "bg-[#43517F] text-[#E4E7F1]"}`}>
                                        2
                                    </div>
                                </div>
                            </div>
                            {
                                chooseTabMyBet === 1 && (<MyBet />)
                            }
                            {
                                chooseTabMyBet === 2 && (<Running />)
                            }
                            {
                                chooseTabMyBet === 3 && (<Settled />)
                            }
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}