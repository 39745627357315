import { Collapse_img, Dollar, Expand, calendar, empty } from "../../../components/imgExport"
import type { Dayjs } from 'dayjs';
import { Collapse, DatePicker } from 'antd';
import type { TimeRangePickerProps } from 'antd';
import dayjs from "dayjs";
import { HeaderItem } from "../Item/HeaderItem";
import { ItemSoccerStatement } from "../Item/ItemSoccerStatement";
const data = []
const { RangePicker } = DatePicker;
export const MLBStatement: React.FC = () => {
    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        } else {
            console.log('Clear');
        }
    };
    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'This day', value: [dayjs('presets'), dayjs()] },
        { label: 'This week', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'Last week', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'Past 7 Day', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'This month', value: [dayjs().add(-30, 'd'), dayjs()] },
        { label: 'Last mont', value: [dayjs().add(-30, 'd'), dayjs()] },
    ];
    return (
        <div className={`px-[48px]  items-center w-full border-r border-r-[#202530] border-l border-l-[#202530] border-b border-b-[#202530] ${data.length === 0 ? 'h-[calc(100vh-235px)]' : ''}`}>
            {
                data.length > 0
                    ? (
                        <div className="flex flex-col gap-6 h-[calc(100vh-235px)]">
                            <div className="flex items-center justify-between px-6">
                                <div className="flex items-center rounded-lg border border-[#4B5563] px-4 py-3">
                                    <img src={calendar} alt="" />
                                    <RangePicker
                                        presets={rangePresets}
                                        onChange={onRangeChange}
                                        size="large"
                                        style={{ width: '325px' }}
                                        suffixIcon={false}
                                        variant="borderless"
                                        className="custom-picker"
                                        renderExtraFooter={() => <div className="flex w-full gap-4 pb-4">
                                            <button className="w-full bg-[#131620] rounded-lg border border-[#C1C9E1] text-[#C1C9E1] text-xs font-medium py-2">
                                                Cancel
                                            </button>
                                            <button className="w-full bg-[#D4B962] rounded-lg   text-[#FFFFFF] text-xs font-medium py-2">
                                                OK
                                            </button>
                                        </div>
                                        }
                                    />
                                </div>
                                <div className="flex items-center gap-2">
                                    <img src={Dollar} alt="" />
                                    <span className="font-bold text-sm text-[#E4E7F1] uppercase">opening Balance</span>
                                    <span className="font-bold text-sm text-[#C59D0F] uppercase">1986 $</span>
                                </div>
                            </div>
                            <div className="flex flex-col gap-[18px] h-[calc(100vh-300px)] overflow-y-scroll">
                                <Collapse
                                    className=' w-full w-colappse  collapse-league px-6-collapse '
                                    expandIconPosition='end'
                                    expandIcon={
                                        (expanded) => (
                                            expanded.isActive ? (
                                                <div className='w-full flex justify-center gap-2 '>
                                                    <span className="uppercase text-base fw-[400] !text-[#E4E7F1]">Collapse</span>
                                                    <img src={Collapse_img} alt="" />
                                                </div>

                                            ) : (
                                                <div className='w-full flex justify-center gap-2 '>
                                                    <span className="uppercase text-base fw-[400] !text-[#E4E7F1]">expand</span>
                                                    <img src={Expand} alt="" />
                                                </div>
                                            )
                                        )
                                    }
                                    items={[{
                                        label: <div className="flex flex-col gap-[10px]">
                                            <div className='text-[#D4B962] fw-[500] text-base flex items-center uppercase'>
                                                <div className='h-[21px] bg-[#42391A] rounded-tl-md rounded-bl-md w-[10px]'>
                                                </div>
                                                07/15/2024
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <span className="text-sm !text-[#E4E7F1]">Total Win/Lose <span className="text-sm !text-[#33E49A]">+ 1000 $</span></span>
                                                <span className="text-sm !text-[#E4E7F1]">Commision  <span className="text-sm !text-[#33E49A]">+ 10 $</span></span>
                                                <span className="text-sm !text-[#E4E7F1]">Running Total <span className="text-sm !text-[#33E49A]">+ 1698 $</span></span>
                                            </div>
                                        </div>,
                                        children:
                                            <div className="flex flex-col">
                                                <HeaderItem />
                                                <div className="flex flex-col w-full h-[calc(100vh-460px)] overflow-y-scroll">
                                                    <ItemSoccerStatement status={1} />
                                                    <ItemSoccerStatement status={2} />
                                                    <ItemSoccerStatement status={2} />
                                                    <ItemSoccerStatement status={2} />
                                                </div>
                                            </div>
                                    }]}
                                />
                            </div>
                        </div>
                    )
                    : (
                        <div className="flex flex-col">
                            <div className="pt-[120px] flex flex-col items-center gap-3">
                                <img src={empty} alt="" className="w-[125px] h-[125px] " />
                                <span className='text-base fw-[400] text-[#C1C9E1]'>You haven't placed any bets yet</span>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}