// import axios from "axios";
import qs from 'qs';
// import * as ENV from "../env";
// import tokenService from '../services/token';

// const axiosClient365 = axios.create({
//   baseURL: ENV.API_URL_365,
//   headers: {
//     'content-type': 'application/x-www-form-urlencoded',
//     // 'content-type': 'multipart/form-data',
//   },
//   paramsSerializer: {
//     serialize: params => {
//       return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true });
//     }
//   }
// });

// axiosClient365.interceptors.request.use(async (config) => {
//   const token = tokenService.getToken();
//   if (token)
//     config.headers.Authorization = 'Bearer ' + token;
//   return config;
// }, (error) => {
//   return Promise.reject(error);
// });

// axiosClient365.interceptors.response.use((response) => {
//   if (response && response.data) {
//     return response.data;
//   }
//   return response;
// }, (error) => {
//   if (error.response && error.response.data && error.response.data.error &&
//     (error.response.data.session === false || error.response.data.session === "false")) {
//     // alert("Đã xảy ra sự cố, sẽ tự động đăng xuất.");
//     window.location.href = "/";
//   } else if (error?.response && error.response?.data && error.response.data.error && error.response.data.error.message) {
//     return error?.response?.data
//   } else if (error.response && error.response.status === 500) {
//     return error?.response?.data;
//   } else if (error?.response && error?.response?.status === 401) {
//     return Promise.reject(error);
//   }
//   else if (error?.response && error?.response?.status === 403) {
//     return Promise.reject(error)
//   }
//   else {
//     return error?.response?.data;
//   }
//   Promise.reject(error);
// });

// export default axiosClient365;





import axios from 'axios';
import * as ENV from "../env";
import tokenService from '../services/token';

const axiosClient365 = axios.create({
  baseURL: ENV.API_URL_365,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  transformRequest: [(data, headers) => {
    return qs.stringify(data);
  }],
});

axiosClient365.interceptors.request.use(async (config) => {
  const token = tokenService.getToken();
  if (token)
    config.headers.Authorization = 'Bearer ' + token;
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosClient365.interceptors.response.use((response) => {
  if (response && response.data) {
    return response.data;
  }
  return response;
}, (error) => {
  if (error.response && error.response.data && error.response.data.error &&
    (error.response.data.session === false || error.response.data.session === "false")) {
    // alert("Đã xảy ra sự cố, sẽ tự động đăng xuất.");
    window.location.href = "/";
  } else if (error?.response && error.response?.data && error.response.data.error && error.response.data.error.message) {
    return error?.response?.data
  } else if (error.response && error.response.status === 500) {
    return error?.response?.data;
  } else if (error?.response && error?.response?.status === 401) {
    return Promise.reject(error);
  }
  else if (error?.response && error?.response?.status === 403) {
    return Promise.reject(error)
  }
  else {
    return error?.response?.data;
  }
  Promise.reject(error);
});

export default axiosClient365;
