import React, { useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import { ChooseBetSoccer } from '../../../../../api/betting/bettingApi';
import { checkSelectSoccerNew_V2, getChangeClass_V3, getChangeColorSoccer_V3 } from '../../../../../until';
import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import { InfoGameDetailSoccer, Odd } from '../../../../../api/homePage/homePage_V2Api';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import * as bettingApi from '../../../../../api/betting/bettingApi';
interface SoccerTopHDPFProps {
  live?: boolean
  homeTeam: string
  awayTeam: string
  homeScore?: number
  awayScore?: number
  item: InfoGameDetailSoccer[]
  oddsOld?: Odd[]
  listChooseBet: ChooseBetSoccer[]
  listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
  chooseBetTop?: (
    order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
  ) => void
  checkUpdateStrightBetsSlip?: (
    id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number
  ) => void
  updateOdds?: any[]
  dateFilterSoccer?: string
  listDateFilterSoccer?: homePage_V2Api.infoListDate[]
}
const SoccerTopHDPFSideBet: React.FC<SoccerTopHDPFProps> = ({
  live, homeTeam, awayTeam, item, listChooseBet, listAddStrightBetsSlipSoccer, oddsOld, homeScore, awayScore, dateFilterSoccer, listDateFilterSoccer,
  chooseBetTop, checkUpdateStrightBetsSlip,
  updateOdds
}) => {
  const odd = useRef<any[]>([item])
  const oddOld = useRef<any[]>([oddsOld])
  const updateArrayOdds = async () => {
    item.forEach(element => {
      updateOdds?.forEach(elementUpdateOdds => {
        if (element.id === elementUpdateOdds.id) {
          let a = odd.current
          setTimeout(() => {
            const abc: any[] = odd.current.map(item => {
              return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
            })
            odd.current = abc
            oddOld.current = a
          }, 500);
        }
      });
    });
    setItemState(odd.current);
    setTimeout(() => {
      oddOld.current = odd.current
    }, 2000);
  }
  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         if (updateOdds) {
  //             // setItemStateOld(oddOld.current)
  //             setItemState(odd.current);
  //         } else {
  //             // setItemStateOld(odd.current)
  //             setItemState(odd.current);
  //         }
  //     }, 3000);
  //     return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    updateArrayOdds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOdds])

  const [itemState, setItemState] = useState<any[]>([item]);
  useEffect(() => {
    item.forEach(itemDetail => {
      itemDetail.prices.sort((a, b) => {
        if (a.option === "h") return -1; // Đưa option = "h" lên đầu
        if (b.option === "h") return 1; // Đưa option = "h" lên đầu
        return 0; // Giữ nguyên thứ tự nếu option không phải "h"
      });
    });
    if (oddsOld) {
      oddsOld.forEach(itemDetail => {
        itemDetail.prices.sort((a, b) => {
          if (a.option === "h") return -1; // Đưa option = "h" lên đầu
          if (b.option === "h") return 1; // Đưa option = "h" lên đầu
          return 0; // Giữ nguyên thứ tự nếu option không phải "h"
        });
      });
    }
    setItemState(item)
    odd.current = item
    oddOld.current = oddsOld ? oddsOld : item
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  // useEffect(() => {
  //     setTimeout(() => {
  //         oddOld.current = odd.current
  //     }, 3000);
  // }, [itemState])

  return (
    <>
      <div className={`${live ? 'w-1/3' : 'w-1/4'} flex flex-col justify-between a1x2`}>
        <div className={`flex justify-between w-full h-[230px] items-center border-dashed border-b border-[#202531]`}>
          <div className='w-full flex flex-col hdp'>
            <div className={`justify-center w-full flex items-center `}>
              {/* // Fulltime HDP */}
              {itemState && itemState.length > 1 && itemState[1].prices && itemState[1].prices.length > 0
                ?
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[1]?.prices?.[0]?.price, oddOld.current?.[1]?.prices?.[0]?.price,
                  () => (listChooseBet && odd.current?.[1]?.prices?.[0]?.price !== oddOld.current?.[1]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[1].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[1].id)].option === itemState?.[1]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[1].id, (Number((itemState[1].point)) !== 0 ? ((-itemState[1].point)) : "0") + " " + itemState[1].prices[0].price, itemState?.[1]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                  dateFilterSoccer,
                  listDateFilterSoccer
                )} 
                                    ${checkSelectSoccerNew_V2(1, 0, itemState[1]?.id ?? 0, listChooseBet, homeTeam, "Fulltime HDP")}`}
                  onClick={() => chooseBetTop?.(
                    1, itemState[1].id, homeTeam, (Number((itemState[1].point)) !== 0 ? ((-itemState[1].point)) : "0") + " " + itemState[1].prices[0].price, itemState?.[1]?.prices?.[0]?.option
                  )}
                >
                  <p className='text-[#E4E7F1] lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base'>{itemState[1].point >= 0 ? -Math.abs(itemState[1].point) : ""}</p>
                  <p><b className='text-[#E4E7F1] lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{Number(itemState[1].prices[0]?.price).toFixed(2)}</b></p>
                  <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[1]?.prices?.[0]?.price, oddOld.current?.[1]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />

                </div>
                :
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}>
                  <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                </div>}
            </div>
            <div className={`justify-center w-full flex items-center `}>
              {itemState && itemState.length > 1 && itemState[1].prices && itemState[1].prices.length > 1
                ?
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[1]?.prices?.[1]?.price, oddOld.current?.[1]?.prices?.[1]?.price,
                  () => (listChooseBet && odd.current?.[1]?.prices?.[1]?.price !== oddOld.current?.[1]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[1].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[1].id)].option === itemState?.[1]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[1].id, (Number((itemState[1].point)) !== 0 ? ((itemState[1].point)) : "0") + " " + itemState[1].prices[1].price, itemState?.[1]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                  dateFilterSoccer,
                  listDateFilterSoccer
                )} 
                                ${checkSelectSoccerNew_V2(1, 0, itemState[1]?.id ?? 0, listChooseBet, awayTeam, "Fulltime HDP")}`}
                  onClick={() => chooseBetTop?.(
                    1, itemState[1].id, awayTeam, (Number((itemState[1].point)) !== 0 ? ((itemState[1].point)) : "0") + " " + itemState[1].prices[1].price, itemState?.[1]?.prices?.[1]?.option
                  )}
                >
                  <p className='text-[#E4E7F1] lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base'>{itemState[1].point < 0 ? -Math.abs(itemState[1].point) : ""}</p>
                  <p><b className='text-[#E4E7F1] lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{Number(itemState[1].prices[1]?.price).toFixed(2)}</b></p>
                  <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[1]?.prices?.[1]?.price, oddOld.current?.[1]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />

                </div>
                :
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}>
                  <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                </div>}
            </div>
            <div className={`justify-center w-full flex items-center`}>
              <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative`}>
                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
              </div>
            </div>
          </div>
        </div>
        <div className={`flex justify-between w-full h-[230px] items-center mt-4 border-dashed border-b border-[#202531]`}>
          <div className='w-full flex flex-col hdp'>
            <div className={`justify-center w-full flex items-center `}>
              {/* // Fulltime HDP */}
              {itemState && itemState.length > 2 && itemState[2].prices && itemState[2].prices.length > 0
                ?
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[2]?.prices?.[0]?.price, oddOld.current?.[2]?.prices?.[0]?.price,
                  () => (listChooseBet && odd.current?.[2]?.prices?.[0]?.price !== oddOld.current?.[2]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[2].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[2].id)].option === itemState?.[2]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[2].id, (Number(itemState[2].point) !== 0 ? (-itemState[2].point) : "0") + " " + itemState[2].prices[0].price, itemState?.[2]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                  dateFilterSoccer,
                  listDateFilterSoccer
                )} 
                                ${checkSelectSoccerNew_V2(2, 0, itemState[2]?.id ?? 0, listChooseBet, homeTeam, "Fulltime HDP")}`}
                  onClick={() => chooseBetTop?.(
                    2, itemState[2].id, homeTeam, (Number(itemState[2].point) !== 0 ? (-itemState[2].point) : "0") + " " + itemState[2].prices[0].price, itemState?.[2]?.prices?.[0]?.option
                  )}
                >
                  <p className='text-[#E4E7F1] lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base'>{itemState[2].point >= 0 ? -Math.abs(itemState[2].point) : ""}</p>
                  <p><b className='text-[#E4E7F1] lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{Number(itemState[2].prices[0]?.price).toFixed(2)}</b></p>
                  <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[2]?.prices?.[0]?.price, oddOld.current?.[2]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />

                </div>
                :
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                >
                  <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                </div>}
            </div>
            <div className={`justify-center w-full flex items-center `}>
              {itemState && itemState.length > 2 && itemState[2].prices && itemState[2].prices.length > 1
                ?
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[2]?.prices?.[1]?.price, oddOld.current?.[2]?.prices?.[1]?.price,
                  () => (listChooseBet && odd.current?.[2]?.prices?.[1]?.price !== oddOld.current?.[2]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[2].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[2].id)].option === itemState?.[2]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[2].id, (Number(itemState[2].point) !== 0 ? (itemState[2].point) : "0") + " " + itemState[2].prices[1].price, itemState?.[2]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                  dateFilterSoccer,
                  listDateFilterSoccer
                )} 
                                ${checkSelectSoccerNew_V2(2, 0, itemState[2]?.id ?? 0, listChooseBet, awayTeam, "Fulltime HDP")}`}
                  onClick={() => chooseBetTop?.(
                    2, itemState[2].id, awayTeam, (Number(itemState[2].point) !== 0 ? (itemState[2].point) : "0") + " " + itemState[2].prices[1].price, itemState?.[2]?.prices?.[1]?.option
                  )}
                >
                  <p className='text-[#E4E7F1] lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base'>{itemState[2].point < 0 ? -Math.abs(itemState[2].point) : ""}</p>
                  <p><b className='text-[#E4E7F1] lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{Number(itemState[2].prices[1]?.price).toFixed(2)}</b></p>
                  <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[2]?.prices?.[1]?.price, oddOld.current?.[2]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />

                </div>
                :
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                >
                  <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                </div>}
            </div>
            <div className={`justify-center w-full flex items-center`}>
              <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative`}>
                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
              </div>
            </div>
          </div>
        </div>
        <div className={`flex justify-between w-full h-[230px] items-center mt-4 `}>
          <div className='w-full flex flex-col hdp'>

            <div className={`justify-center w-full flex items-center`}>
              {itemState && itemState.length > 3 && itemState[3].prices && itemState[3].prices.length > 0
                ?
                <>
                  <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[3]?.prices?.[0]?.price, oddOld.current?.[3]?.prices?.[0]?.price,
                    () => (listChooseBet && odd.current?.[3]?.prices?.[0]?.price !== oddOld.current?.[3]?.prices?.[0]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[3].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[3].id)].option === itemState?.[3]?.prices?.[0]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[3].id, (Number(itemState[3].point) !== 0 ? (-itemState[3].point) : "0") + " " + itemState[3].prices[0].price, itemState?.[3]?.prices?.[0]?.option, homeScore, awayScore) : ""),
                    dateFilterSoccer,
                    listDateFilterSoccer
                  )} 
                                    ${checkSelectSoccerNew_V2(0, undefined, itemState[3]?.id ?? 0, listChooseBet, homeTeam, "Fulltime HDP")}`}
                    onClick={() => chooseBetTop?.(
                      0, itemState[3].id, homeTeam, (Number(itemState[3].point) !== 0 ? (-itemState[3].point) : "0") + " " + itemState[3].prices[0].price, itemState?.[3]?.prices?.[0]?.option
                    )}
                  >
                    <p className='text-[#E4E7F1] lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base'>{itemState[3].point >= 0 ? -Math.abs(itemState[3].point) : ""}</p>
                    <p><b className='text-[#E4E7F1] lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{Number(itemState[3].prices[0]?.price).toFixed(2)}</b></p>
                    <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[3]?.prices?.[0]?.price, oddOld.current?.[3]?.prices?.[0]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />
                  </div>

                </>
                :
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                >
                  <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                </div>}
            </div>
            <div className={`justify-center w-full flex items-center `}>
              {itemState && itemState.length > 3 && itemState[3].prices && itemState[3].prices.length > 1
                ?
                <>
                  <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative ${getChangeColorSoccer_V3(odd.current?.[3]?.prices?.[1]?.price, oddOld.current?.[3]?.prices?.[1]?.price,
                    () => (listChooseBet && odd.current?.[3]?.prices?.[1]?.price !== oddOld.current?.[3]?.prices?.[1]?.price && listAddStrightBetsSlipSoccer && listAddStrightBetsSlipSoccer?.map((item) => item.id).includes(itemState[3].id) && listAddStrightBetsSlipSoccer[listAddStrightBetsSlipSoccer?.map((item) => item.id).indexOf(itemState[3].id)].option === itemState?.[3]?.prices?.[1]?.option ? checkUpdateStrightBetsSlip && checkUpdateStrightBetsSlip(itemState[3].id, (Number(itemState[3].point) !== 0 ? (itemState[3].point) : "0") + " " + itemState[3].prices[1].price, itemState?.[3]?.prices?.[1]?.option, homeScore, awayScore) : ""),
                    dateFilterSoccer,
                    listDateFilterSoccer
                  )} 
                                    ${checkSelectSoccerNew_V2(0, 0, itemState[3]?.id ?? 0, listChooseBet, awayTeam, "Fulltime HDP")}`}
                    onClick={() => chooseBetTop?.(
                      0, itemState[3].id, awayTeam, (Number(itemState[3].point) !== 0 ? (itemState[3].point) : "0") + " " + itemState[3].prices[1].price, itemState?.[3]?.prices?.[1]?.option
                    )}
                  >
                    <p className='text-[#E4E7F1] lg:text-[10px] xl:text-[10px] xl2:text-base xl3:text-base'>{itemState[3].point < 0 ? -Math.abs(itemState[3].point) : ""}</p>
                    <p><b className='text-[#E4E7F1] lg:text-sm xl:text-sm xl2:text-[18px] xl3:text-[18px]'>{Number(itemState[3].prices[1]?.price).toFixed(2)}</b></p>                                        <CaretRightOutlined className={`${getChangeClass_V3(odd.current?.[3]?.prices?.[1]?.price, oddOld.current?.[3]?.prices?.[1]?.price, dateFilterSoccer, listDateFilterSoccer)}`} />

                  </div>
                </>
                :
                <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md`}
                >
                  <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                </div>}
            </div>
            <div className={`justify-center w-full flex items-center`}>
              <div className={`h-[64px] ratioDetail ratioDetailSoccer flex flex-col justify-center w-full rounded-md relative`}>
                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default SoccerTopHDPFSideBet;