import { Link } from "react-router-dom";
import { Form, Input } from "antd";
import { logoKB_2, KB_best_sport_img_2, contact, phone } from "../../../components/imgExport";
import '../style.css'
export const ForgotByPhoneNumber: React.FC = () => {
    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
    };

    return (
        <div className='h-[100vh] !w-full flex  !justify-center !items-center bg-login-new'>
            <div className='flex flex-col xs:w-[343px] xs:gap-3 sm:w-[343px] sm:gap-3 md:w-[694px] md:gap-4 lg:w-[694px] lg:gap-4 items-center rounded-[24px] border border-[#454449] bg-[#14171F]'>
                <div className='flex flex-col items-center xs:gap-3 sm:gap-3 md:gap-6 lg:gap-6 '>
                    <div className="xs:pt-[24px] sm:pt-[24px] md:pt-[44px] lg:pt-[44px]">
                        <img src={logoKB_2} alt="" className="xs:w-[60px] xs:h-[60px] sm:w-[60px] sm:h-[60px] md:w-[120px] md:h-[120px] lg:w-[120px] lg:h-[120px]" />
                    </div>
                    <img src={KB_best_sport_img_2} alt="" />
                    <div className="flex flex-col items-center gap-3">
                        <span className="xs:text-xs sm:text-xs md:text-lg lg:text-lg text-[#E4E7F1] uppercase">please confirm your phone number</span>
                        <span className="xs:text-xs sm:text-xs md:text-sm lg:text-sm text-[#E4E7F1] uppercase">Hint: 09777****12</span>
                    </div>
                </div>

                <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    className="xs:w-[279px] sm:w-[279px] md:w-[356px] lg:w-[356px]"
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your Username!' }]}
                        className="custom-input rounded-lg border-4B5563"
                    >
                        <Input
                            prefix={<img src={phone} className="xs:w-[12px] xs:h-[12px] sm:w-[12px] sm:h-[12px] md:w-[24px] md:h-[24px] lg:w-[24px] lg:h-[24px]" />}
                            placeholder='Your phone number'
                            size="large"
                            className="!text-[#C1C9E1] placehoder-C1C9E1"
                        />
                    </Form.Item>
                    <div className="flex flex-col gap-6 items-center w-full xs:pb-0 sm:pb-0 md:pb-6 lg:pb-6">
                        <button className="xs:text-xs  sm:text-xs md:text-sm lg:text-sm bg-[#E1C467] text-[#131620]  rounded-lg xs:py-3 sm:py-3 md:py-[14px] lg:py-[14px] w-full font-bold uppercase" type="submit">
                            confirm
                        </button>
                    </div>
                </Form>
                <div className="flex gap-[14px] xs:pb-6 sm:pb-6 md:pb-[59px] lg:pb-[59px]">
                    <img src={contact} alt="" />
                    <div className="flex flex-col">
                        <span className="uppercase text-sm text-[#FFFFFF] fw-[500]">Need support?</span>
                        <Link to={'/'} className="text-[12px] text-[#D4B962] uppercase">contact us</Link>
                    </div>
                </div>
            </div>

        </div>
    )
}
