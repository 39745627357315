/* eslint-disable @typescript-eslint/no-unused-vars */
import axiosClient from "../axiosClient";
import axiosClient365 from "../axiosClient365";

import { paserParams } from "../../until";
import authuClient from "../authuClient";
import { ResponseSuccess } from "../axiosClient";


export interface CreateLogin {
    success?: boolean,
    id: number,
    token: string;
    message: string;
    messageEnglish: string;
    data: [];
}

export interface ILogin<T> {
    success?: boolean;
    message: string;
    token: string;
}

export interface ILoginInfo {
    id?: number;
    email?: string;
    phone?: string;
    // password?: string;
    // delf?: number;
}

export interface ILoginOTPInfo {
    id?: number;
    seri_number?: string;
    machine_name?: string;
    authcode?: string;
    current_money?: number;
    status?: number;
    created_at?: any;
}

export interface ILoginOTP<T> {
    success?: boolean;
    message: string;
    token: string;
    data?: ILoginOTPInfo;
}

export interface OTPCode {
    id?: string;
}

// export const createAccountWithPhone = async (code: string, phone: number, fullname: string, password: string) => {
//     const path = "createAccountWithPhone.php"
//     const params = {
//         code,
//         phoneNumber: phone,
//         fullname,
//         password,
//     }
//     const result: ILogin<[]> = await authuClient.post(path,
//         paserParams(params, undefined)
//     );
//     return result;
// };

export const checkPhone = async (code: string, phone: number) => {
    const path = "checkAccountWithPhone.php"
    const params = {
        code,
        phoneNumber: phone
    }
    const result: ResponseSuccess<[]> = await authuClient.post(path,
        { params: paserParams(params, undefined) }
    );
    return result;
};


export const login = async (username: string, password: string) => {
    const path = "v1/auth/login"
    const params = {
        username,
        password,
    }
    const result: ILogin<[]> = await authuClient.post(path,
        paserParams(params, undefined)
    );
    return result;
};

export const loginOTP = async (authCode: string) => {
    const path = "v1/auth/build-bill-acceptor"
    const params = {
        authCode,
    }
    const result: ILoginOTP<[]> = await authuClient.post(path,
        paserParams(params, undefined)
    );
    return result;
};

export const getAuthcodeByToken = async () => {
    const path = "v1/auth/get-authcode-by-token"
    const params = {}
    const result: OTPCode = await axiosClient.get(path,
        paserParams(params, undefined)
    );
    return result;
};

export const loginByUsername = async (userName: string, password: string) => {
    const path = "api/user/login"
    const params = {
        userName,
        password
    }
    const result: ResponseSuccess<any> = await axiosClient365.post(path,
        paserParams(params, undefined)
    );
    return result;
};


export const forgotPasswordWithUsername = async (userName: string) => {
    const path = "api/user/forgot-password-with-username"
    const params = {
        userName,
    }
    const result: ResponseSuccess<any> = await axiosClient365.post(path,
        paserParams(params, undefined)
    );
    return result;
};

export const forgotUsername = async (email: string) => {
    const path = "api/user/forgot-username"
    const params = {
        email,
    }
    const result: ResponseSuccess<any> = await axiosClient365.post(path,
        paserParams(params, undefined)
    );
    return result;
};

export const forgotPasswordConfirmEmail = async (email: string, code: string) => {
    const path = "api/user/forgot-password-confirm-email"
    const params = {
        email,
        code
    }
    const result: ResponseSuccess<any> = await axiosClient365.post(path,
        paserParams(params, undefined)
    );
    return result;
};

export const getUserInformation = async () => {
    const path = "api/user/get-user-information"
    const params = {}
    const result: ResponseSuccess<any> = await axiosClient365.get(path,
        paserParams(params, undefined)
    );
    return result;
};

export const changeUsername = async (username: string) => {
    const path = "api/user/change-username"
    const params = { username }
    const result: ResponseSuccess<any> = await axiosClient365.post(path,
        paserParams(params, undefined)
    );
    return result;
};

export const changePassword = async (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
    const path = "api/user/change-password"
    const params = { oldPassword, newPassword, confirmNewPassword }
    const result: ResponseSuccess<any> = await axiosClient365.post(path,
        paserParams(params, undefined)
    );
    return result;
};

