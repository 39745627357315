import { useState } from "react"
import { soccerActice, soccer, baketballActive, baketball, rugbyActive, rugby, baseballActive, baseball } from "../../../components/imgExport"
import { SettledSoccer } from "./Component/SettledSoccer"
import { SettledNBA } from "./Component/SettledNBA"
import { SettledNFL } from "./Component/SettledNFL"
import { SettledMLB } from "./Component/SettledMLB"


export const Settled: React.FC = () => {
    const [chooseGame, setChooseGame] = useState<number>(1)

    return (
        <div className="w-full">
            <div className="w-full flex items-center justify-center py-6 border-r border-r-[#202530] border-l border-l-[#202530]">
                <div className="flex items-center gap-2 border-r border-r-[#212632]"
                    onClick={() => setChooseGame(1)}
                >
                    <img src={`${chooseGame === 1 ? soccerActice : soccer}`} alt="" />
                    <span
                        className={`uppercase text-sm  pr-[51px] ${chooseGame === 1 ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                    >
                        soccer
                    </span>
                </div>
                <div className="flex items-center gap-2 border-r border-r-[#212632]"
                    onClick={() => setChooseGame(2)}
                >
                    <img src={`${chooseGame === 2 ? baketballActive : baketball}`} alt="" className="pl-[51px]" />
                    <span className={`uppercase text-sm  pr-[51px] ${chooseGame === 2 ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>nba</span>
                </div>
                <div className="flex items-center gap-2 border-r border-r-[#212632]"
                    onClick={() => setChooseGame(3)}
                >
                    <img src={`${chooseGame === 3 ? rugbyActive : rugby}`} alt="" className="pl-[51px]" />
                    <span className={`uppercase text-sm  pr-[51px] ${chooseGame === 3 ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>nfl</span>
                </div>
                <div className="flex items-center gap-2 "
                    onClick={() => setChooseGame(4)}
                >
                    <img src={`${chooseGame === 4 ? baseballActive : baseball}`} alt="" className="pl-[51px]" />
                    <span className={`uppercase text-sm   ${chooseGame === 4 ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>mlb</span>
                </div>
            </div>
            {
                chooseGame === 1 && (<SettledSoccer />)
            }
            {
                chooseGame === 2 && (<SettledNBA />)
            }
            {
                chooseGame === 3 && (<SettledNFL />)
            }
            {
                chooseGame === 4 && (<SettledMLB />)
            }
        </div>
    )
}