import { empty } from "../../../../components/imgExport"
const data = []
export const SettledMLB: React.FC = () => {
    return (
        <div className={`px-[48px]  items-center w-full border-r border-r-[#202530] border-l border-l-[#202530] border-b border-b-[#202530] ${data.length === 0 ? 'h-[calc(100vh-235px)]' : ''}`}>
            {
                data.length > 0
                    ? (
                        <div className="flex flex-col">
                            <div className="flex w-full bg-header rounded-tl-lg rounded-tr-lg">
                                <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[255px]">
                                    <span className="text-sm text-[#E4E7F1] font-bold ">TIme & ID</span>
                                </div>
                                <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[255px]">
                                    <span className="text-sm text-[#E4E7F1] font-bold">Game</span>
                                </div>
                                <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[255px]">
                                    <span className="text-sm text-[#E4E7F1] font-bold uppercase">odds</span>
                                </div>
                                <div className="flex justify-center py-[7px] border-r border-r-[#282C38] w-[255px]">
                                    <span className="text-sm text-[#E4E7F1] font-bold uppercase">stake</span>
                                </div>
                                <div className="flex justify-center py-[7px] w-[255px]">
                                    <span className="text-sm text-[#E4E7F1] font-bold uppercase">Status</span>
                                </div>
                            </div>
                            <div className="flex flex-col w-full h-[calc(100vh-270px)] overflow-y-scroll">
                            </div>
                        </div>
                    )
                    : (
                        <div className="flex flex-col">
                            <div className="pt-[120px] flex flex-col items-center gap-3">
                                <img src={empty} alt="" className="w-[125px] h-[125px] " />
                                <span className='text-base fw-[400] text-[#C1C9E1]'>You haven't placed any bets yet</span>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}