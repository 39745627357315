/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from "antd"
import { useEffect, useRef, useState } from "react";
import { LogoBlack } from "../imgExport";
// SoccerBlack
import { InfoBillParlaySoccer, InfoBillStrightSoccer } from "../../api/betting/bettingApi";
import './styles.css'
import { formatPrice } from "../../until";
import { useSelector } from "react-redux";
import { selectorUser } from '../../store/slices/userSlice';
import { QRCode } from "react-qrcode-logo";
import { useReactToPrint } from "react-to-print";
import { ICON_GAMME } from "../../env";
interface Props {
    showModalBill: boolean,
    totalAmount: number,
    billCode?: string
    listBillStright: InfoBillStrightSoccer[],
    listBillParlay?: InfoBillParlaySoccer,

    setShowModalBill: (value: boolean) => void
}

// function formatDate(UTC: string) {
//     let d = new Date(UTC).toLocaleDateString()
//     return d
// }

function formatDateNow() {
    let d = new Date().toLocaleDateString()
    return d
}

// function formatTimeNow() {
//     let d = new Date().toLocaleTimeString()
//     return d
// }

// declare global {
//     interface Window {
//         electron: {
//             printContent: (content: any) => void;
//         };
//         electronAPI: {
//             printComponent: (content: string, response: any) => void;
//             previewComponent: (content: string, response: any) => void;

//         }
//     }
// }

function formatTimeNow() {
    let date = new Date()
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let period = hours >= 12 ? 'PM' : 'AM';

    // Chuyển đổi sang định dạng 12 giờ
    hours = hours % 12 || 12;

    return hours.toString().padStart(2, "0") + ':' + minutes.toString().padStart(2, "0") + ' ' + period;
}

function formatDateWithDate(date: any) {
    let d = new Date(date).toLocaleDateString()
    return d
}

function formatTimeWithDate(day: any) {
    let date = new Date(day)
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let period = hours >= 12 ? 'PM' : 'AM';

    // Chuyển đổi sang định dạng 12 giờ
    hours = hours % 12 || 12;

    return hours.toString().padStart(2, "0") + ':' + minutes.toString().padStart(2, "0") + ' ' + period;
}

const ModalBillSoccer: React.FC<Props> = ({
    showModalBill, totalAmount, billCode, listBillStright, listBillParlay,
    setShowModalBill
}) => {
    // const { userId, presentMoney, authCode, machineCode } = useSelector(selectorUser);
    const { machineCode } = useSelector(selectorUser);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [keytab, setKeytab] = useState('STRAIGHT')

    const checkKeyTab = () => {
        if (listBillStright.length > 0) {
            setKeytab('STRAIGHT')
        } else if (listBillParlay && listBillParlay.data.length > 0) {
            setKeytab('PARLAY')
        }
    }

    useEffect(() => {
        checkKeyTab()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const contentToPrint = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handlePrint = useReactToPrint({
        // documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleShare = useReactToPrint({
        content: () => contentToPrint.current,
        documentTitle: `123.pdf`,
        copyStyles: true,
        print: async (printIframe: HTMLIFrameElement) => {
            const document = printIframe.contentDocument;
            if (document) {
                const html = document.getElementsByTagName('html')[0];
                console.log(html);
                // await html2pdf().from(html).save();
            }
        }
    });


    const handlePrint2 = function (target: any) {
        return new Promise(() => {
            console.log("forwarding print request to the main process...");
            var text: any
            if (listBillStright.length > 0) {
                text = `
<style>
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    box-sizing: border-box !important;
  }
  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
  }

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Oswald', sans-serif;
  }
</style>

 <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script>

 <script>
 var qrcode = document.getElementsByClassName("qrcode");
 for (let i = 0; i < qrcode.length; i++) {
    var qrcode123 = new QRCode(document.getElementById(qrcode[i].id), {
      text: qrcode[i].getAttribute("data-value"),
      width: 128,
      height: 128,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H
    });
 }
 
    </script>`
            } else {
                text = `
                    <style>
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    box-sizing: border-box;
  }

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  div,
  p,
  span,
  i,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Oswald', sans-serif;
  }
</style>
 <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script>

 <script>
 var qrcode = document.getElementsByClassName("qrcode");
 
        var qrcode = new QRCode(document.getElementById("qrcode-parlay"), {
         text: "${billCode}",
         width: 128,
         height: 128,
     });
    </script>`
            }

            // ${
            //                 listBill.map((item, i) => (`
            //         var qrcode-${i} = new QRCode(document.getElementById("qrcode-0"), {
            //          text: "123",
            //          width: 128,
            //          height: 128,
            //      });`

            //                 ))
            //             }
            const data = target.contentWindow.document.documentElement.outerHTML;
            const blob = new Blob([data + text], { type: "text/html" });


            const url = URL.createObjectURL(blob);
            console.log(url);


            window.electronAPI.printComponent(url, (response: any) => {
                console.log("Main: ", response);
            });

            //console.log('Main: ', data);
        });
    };

    const handleBillPrint = useReactToPrint({
        content: () => contentToPrint.current,
        documentTitle: "Bill component",
        print: handlePrint2,
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("sau khi in"),
    });

    useEffect(() => {
        if (showModalBill) {
            setTimeout(() => {
                handleBillPrint()
            }, 500)
            setTimeout(() => {
                setShowModalBill(false);
            }, 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalBill]);


    return (

        <Modal
            className='billRefund flex justify-center'
            title={null}
            open={showModalBill}
            onOk={() => setShowModalBill(false)}
            onCancel={() => setShowModalBill(false)}
            footer={null}
            closable={false}
        >
            {/* <div className="flex justify-end mb-3">
                <button className="bg-[#BB271A] text-white rounded px-1 font-bold" onClick={() => {
                    handleBillPrint()
                }}>
                    Print
                </button>
            </div> */}
            <div className="text-sm max-h-[70vh] overflow-y-auto">
                {listBillStright.length === 0 && (
                    <>
                        <div className='flex justify-between items-center'>
                            <div className="w-[30%]"><img src={LogoBlack} alt="LogoBlack" /></div>
                            <div><span className='font-bold text-[18px]'>KINGBETS</span></div>
                            <div className="w-[30%]"></div>
                        </div>
                        <div className="px-2 text-[#000000] text-right text-[12px]">
                            <span>{listBillStright.length ? formatDateWithDate(listBillStright[0].date) : formatDateNow()} | {listBillStright.length ? formatTimeWithDate(listBillStright[0].date) : formatTimeNow()} </span>

                        </div>
                        <div className="w-full overflow-hidden">
                            <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                        </div>
                        <div>
                            <p className='px-2 font-bold text-[#000000]'>Bill Code: {billCode}</p>
                            <p className='px-2 font-bold text-[#000000]'>Machine Code: {machineCode}</p>
                        </div>
                        <div className="w-full overflow-hidden">
                            <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                        </div>
                    </>
                )}
                {/* <div className="text-[#262626] text-right text-[12px]">
                        <span>14/02/2024 | 13:33 AM </span>
                    </div>
                    <div className="w-full overflow-hidden">
                                            <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                                        </div>
                    <div>
                        <p className='px-2 font-bold text-[#262626]'>Bill Code: ACV433434</p>
                        <p className='px-2 font-bold text-[#262626]'>Machine Code: M98455</p>
                    </div>
                    <div className="w-full overflow-hidden">
                                            <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                                        </div> */}

                {
                    listBillStright && listBillStright?.map((item, i) =>
                        <>
                            <div key={i} className="break-page">
                                <div>
                                    <div className='flex justify-between items-center'>
                                        <div className="w-[30%]"><img src={LogoBlack} alt="LogoBlack" /></div>
                                        <div><span className='font-bold text-[18px]'>KINGBETS</span></div>
                                        <div className="w-[30%]"></div>
                                    </div>
                                    <div className="pr-2 text-[#000000] text-right text-[12px]">
                                        <span>{listBillStright.length ? formatDateWithDate(listBillStright[0].date) : formatDateNow()} | {listBillStright.length ? formatTimeWithDate(listBillStright[0].date) : formatTimeNow()} </span>
                                    </div>
                                    <div className="w-full overflow-hidden">
                                        <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                                    </div>
                                    <div>
                                        <p className='px-2 font-bold text-[#000000]'>Bill Code: {item.billCode}</p>
                                        <p className='px-2 font-bold text-[#000000]'>Machine Code: {machineCode}</p>
                                    </div>
                                    <div className="w-full overflow-hidden">
                                        <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="w-full flex justify-between px-2">
                                        <p>STRAIGHT BET | {listBillStright.length} BET </p>
                                        <p>Soccer</p>
                                    </div>
                                    {/* <p className="px-2 ">14/02/2024</p> */}
                                    <div className="w-full overflow-hidden">
                                        <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                                    </div>
                                    <div className="pl-2 flex items-center">
                                        {/* <img className="w-[18px] h-[18px]" src={SoccerBlack} alt="NBA" /> */}
                                        <p className='pr-2'>{item.tournament}: {item.lineBetting}</p>
                                    </div>
                                    <p className='px-2 font-bold'>{item.game}</p>
                                    <div className="flex justify-between">
                                        <p className='px-2'>{item.teamChoose}</p>
                                        <p className='px-2 text-right'>{item.scoreChoose}</p>
                                    </div>
                                    {item?.live ? (
                                        <>
                                            <div className="flex">
                                                <p className='px-2 font-bold'>Live</p>
                                            </div>
                                            <div className="flex">
                                                <p className='px-2'>{`${item.homeLiveScore} : ${item.awayLiveScore}`}</p>
                                            </div>
                                        </>
                                    ) :
                                        ""
                                    }
                                    {/* <p className='px-2'>{item.lineBetting}</p> */}
                                    <div className="w-full overflow-hidden">
                                        <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                                    </div>
                                    <div className="pt-2">
                                        <div className="flex justify-between">
                                            <p className='px-2 font-bold text-[#262626]'>Total Stake</p>
                                            <p className='px-2 font-bold text-[#262626]'>{"$" + formatPrice(item.amount)}</p>
                                        </div>
                                        <div className="flex justify-between">
                                            <p className='px-2 font-bold text-[#262626]'>Potential payout</p>
                                            <p className='px-2 font-bold text-[#262626]'>{"$" + formatPrice(item.win.toFixed(0))}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`flex flex-col items-center py-2`}>
                                    <QRCode
                                        size={120}
                                        value={item.billCode ? item.billCode : ""}
                                        // logoImage={logoBill}
                                        logoWidth={30}
                                        logoHeight={30}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
                {listBillParlay?.data && listBillParlay?.data.length > 0 && (
                    <>
                        <div className="w-full flex justify-between px-2">
                            <p>PARLAY BET | {listBillParlay.data.length} BET </p>
                            <p>Soccer</p>
                        </div>
                        <div className="w-full overflow-hidden">
                            <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                        </div>
                    </>
                )}
                {
                    listBillParlay?.data && listBillParlay?.data.map((item, i) => (
                        <div key={i}>

                            <div className="pl-2 flex items-center">
                                {/* <img className="w-[18px] h-[18px]" src={SoccerBlack} alt="NBA" /> */}
                                <p className='pr-2'>{item.tournament}: {item.lineBetting}</p>
                            </div>
                            <p className='px-2 font-bold'>{item.game}</p>
                            <div className="flex justify-between">
                                <p className='px-2'>{item.teamChoose}</p>
                                <p className='px-2 text-right'>{item.scoreChoose}</p>
                            </div>
                            <div className="w-full overflow-hidden">
                                <div className='whitespace-nowrap text-black leading-[5px]'>-------------------------------------------------------</div>
                            </div>
                            {/* <p className='px-2'>{item.lineBetting}</p> */}
                        </div>
                    ))
                }

                <div className={`flex flex-col ${(listBillParlay?.totalOdds) ? "" : "hidden"}`}

                >

                    <div className="pt-2">
                        <div className="flex justify-between">
                            <p className='px-2 font-bold text-[#262626]'><b>Total Odds</b></p>
                            <p className='px-2 font-bold text-[#262626]'>{"" + listBillParlay?.totalOdds}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className='px-2 font-bold text-[#262626]'><b>Total Stake</b></p>
                            <p className='px-2 font-bold text-[#262626]'>${listBillParlay?.totalStake}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className='px-2 font-bold text-[#262626]'><b>Potential payout</b></p>
                            <p className='px-2 font-bold text-[#262626]'>
                                ${formatPrice((Number(listBillParlay?.totalOdds) * Number(listBillParlay?.totalStake)).toFixed(0))}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`flex flex-col items-center pt-8 ${(listBillParlay?.totalOdds) ? "" : "hidden"}`}>
                    <QRCode
                        size={120}
                        value={billCode ? billCode : ""}
                        // logoImage={logoBill}
                        logoWidth={30}
                        logoHeight={30}
                    />
                    {/* <p className='p-4 font-bold'>Ticket is only vaild within 48 hours.</p> */}
                </div>
            </div>
            <div className="hidden">
                <div ref={contentToPrint} style={{ width: '100%', fontSize: '14px', paddingRight: '35px' }}>
                    {listBillStright.length === 0 && (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ width: '50px' }}><img src={ICON_GAMME + 'LogoBlack.svg'} alt="LogoBlack" /></div>
                                <div><span style={{ fontWeight: '700', fontSize: '18px' }}>KINGBETS</span></div>
                                <div style={{ width: '50px' }}></div>
                            </div>
                            <div style={{ paddingLeft: '8px', paddingRight: '8px', color: 'black', textAlign: 'right', fontSize: '12px' }}>
                                <span>{listBillStright.length ? formatDateWithDate(listBillStright[0].date) : formatDateNow()} | {listBillStright.length ? formatTimeWithDate(listBillStright[0].date) : formatTimeNow()} </span>

                            </div>
                            <div style={{ width: '100%', overflow: 'hidden' }} >
                                <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>--------------------------------------------------------------------------------------</div>
                            </div>
                            <div>
                                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Bill Code: {billCode}</p>
                                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Machine Code: {machineCode}</p>
                            </div>
                            <div style={{ width: '100%', overflow: 'hidden' }}>
                                <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>--------------------------------------------------------------------------------------</div>
                            </div>
                        </>
                    )}

                    {
                        listBillStright && listBillStright?.map((item, i) =>
                            <>
                                <div key={i} style={{ pageBreakAfter: i === listBillStright.length - 1 ? "unset" : 'always' }}>
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ width: '50px' }}><img src={ICON_GAMME + 'LogoBlack.svg'} alt="LogoBlack" /></div>
                                            <div><span style={{ fontWeight: '700', fontSize: '18px' }}>KINGBETS</span></div>
                                            <div style={{ width: '50px' }}></div>
                                        </div>
                                        <div style={{ paddingRight: '8px', color: 'black', textAlign: 'right', fontSize: '12px' }}>
                                            {/* <span>{formatDateNow()} | {formatTimeNow()} </span> */}
                                            <span>{listBillStright.length ? formatDateWithDate(listBillStright[0].date) : formatDateNow()} | {listBillStright.length ? formatTimeWithDate(listBillStright[0].date) : formatTimeNow()} </span>

                                        </div>
                                        <div style={{ width: '100%', overflow: 'hidden' }}>
                                            <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>--------------------------------------------------------------------------------------</div>
                                        </div>
                                        <div>
                                            <p className='bill-code' style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }} data-id={item.billCode}>Bill Code: {item.billCode}</p>
                                            <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Machine Code: {machineCode}</p>
                                        </div>
                                        <div style={{ width: '100%', overflow: 'hidden' }}>
                                            <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>--------------------------------------------------------------------------------------</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="w-full flex justify-between px-2" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingLeft: '8px', paddingRight: '8px' }}>
                                            <p>STRAIGHT BET | {listBillStright.length} BET </p>
                                            <p>Soccer</p>
                                        </div>
                                        {/* <p  style={{ paddingLeft: '8px', paddingRight: '8px' }}>14/02/2024</p> */}
                                        <div style={{ width: '100%', overflow: 'hidden' }}>
                                            <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>--------------------------------------------------------------------------------------</div>
                                        </div>
                                        <div style={{ paddingLeft: '8px', display: 'flex', alignItems: 'center' }}>
                                            {/* <img style={{ width: '18px', height: '18px' }} src={SoccerBlack} alt="NBA" /> */}
                                            <p style={{ paddingRight: '8px' }}>{item.tournament}: {item.lineBetting}</p>
                                        </div>
                                        <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700' }}>{item.game}</p>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>{item.teamChoose}</p>
                                            <p style={{ paddingLeft: '8px', paddingRight: '8px', textAlign: 'right' }}>{item.scoreChoose}</p>
                                        </div>
                                        {item?.live ? (
                                            <>
                                                <div style={{ display: 'flex' }}>
                                                    <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700' }}>Live</p>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>{`${item.homeLiveScore} : ${item.awayLiveScore}`}</p>
                                                </div>
                                            </>
                                        ) :
                                            ""
                                        }
                                        {/* <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>{item.lineBetting}</p> */}
                                        <div style={{ width: '100%', overflow: 'hidden' }}>
                                            <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>--------------------------------------------------------------------------------------</div>
                                        </div>
                                        <div style={{ paddingTop: '8px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Total Stake</p>
                                                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>{"$" + formatPrice(item.amount)}</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>Potential payout</p>
                                                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>{"$" + formatPrice(item.win ? item.win.toFixed(0) : item.win)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '8px 0px 8px 0px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                                            <div className={`qrcode`} data-value={item.billCode} id={`qrcode-${i}`}></div>
                                        </div>
                                    </div>
                                </div >
                            </>
                        )
                    }
                    {listBillParlay?.data && listBillParlay?.data.length > 0 && (
                        <>
                            <div className="w-full flex justify-between px-2" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingLeft: '8px', paddingRight: '8px' }}>
                                <p>PARLAY BET | {listBillParlay.data.length} BET </p>
                                <p>Soccer</p>
                            </div>
                        </>
                    )}

                    {
                        listBillParlay?.data && listBillParlay?.data.map((item, i) => (
                            <div key={i}>
                                <div style={{ width: '100%', overflow: 'hidden' }}>
                                    <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>--------------------------------------------------------------------------------------</div>
                                </div>

                                <div style={{ paddingLeft: '8px', display: 'flex', alignItems: 'center' }}>
                                    {/* <img style={{ width: '18px', height: '18px' }} src={SoccerBlack} alt="NBA" /> */}
                                    <p style={{ paddingRight: '8px' }}>{item.tournament}: {item.lineBetting}</p>
                                </div>
                                <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700' }}>{item.game}</p>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>{item.teamChoose}</p>
                                    <p style={{ paddingLeft: '8px', paddingRight: '8px', textAlign: 'right' }}>{item.scoreChoose}</p>
                                </div>

                                {/* <p style={{ paddingLeft: '8px', paddingRight: '8px' }}>{item.lineBetting}</p> */}
                            </div>
                        ))
                    }
                    {
                        listBillParlay?.totalOdds && <div

                            style={{ display: listBillParlay?.totalOdds ? "flex" : "none", flexDirection: 'column', }}

                        >
                            <div style={{ width: '100%', overflow: 'hidden' }}>
                                <div style={{ whiteSpace: 'nowrap', color: 'black', lineHeight: '5px' }}>--------------------------------------------------------------------------------------</div>
                            </div>
                            <div style={{ paddingTop: '8px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}><b>Total Odds</b></p>
                                    <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>{"" + listBillParlay?.totalOdds}</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}><b>Total Stake</b></p>
                                    <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>${listBillParlay?.totalStake}</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}><b>Potential payout</b></p>
                                    <p style={{ paddingLeft: '8px', paddingRight: '8px', fontWeight: '700', color: 'black' }}>
                                        ${formatPrice((Number(listBillParlay?.totalOdds) * Number(listBillParlay?.totalStake)).toFixed(0))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{ width: '100%', display: listBillParlay?.totalOdds ? "" : 'none' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <div id="qrcode-parlay"></div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default ModalBillSoccer