import HeadNavi from "../homepage/components/MainNavi/Head/HeadNavi";
import { useState } from "react";
import { itemMenu } from "../homepage/components/MainNavi/Tabs/TabsNaviV2";
import { soccerActice, soccer, baketballActive, baketball, rugbyActive, rugby, baseballActive, baseball } from "../../components/imgExport";
import { SoccerStatement } from "./Component/SoccerStatement";
import { NBAStatement } from "./Component/NBAStatement";
import { NFLStatement } from "./Component/NFLStatement";
import { MLBStatement } from "./Component/MLBStatement";
import { Layout } from "../../routes/Layout";


export const Statement: React.FC = () => {
    const [tournaments, setTournaments] = useState(itemMenu[0])
    const [chooseGame, setChooseGame] = useState<number>(1)
    const handelChooseTournaments = (value: itemMenu) => {
        const listParent = document.querySelectorAll(".ant-menu-parent-tournament.ant-menu-submenu-selected")
        if (listParent.length > 0) {
            listParent[0].classList.remove("ant-menu-submenu-selected")
        }

        const listChildren = document.querySelectorAll(".ant-menu-children-tournament.ant-menu-item-selected")
        if (listChildren.length > 0) {
            listChildren[0].classList.remove("ant-menu-item-selected")
        }

        setTournaments(value)
        if (value.id !== 9) {
            setTournaments(value)
            // gListGameByTournament(value.id)
            // gListAllBetMGM(value.name)
        }
        if (value.id === 0) {
            // gListOfGamesForTheHomepage()
        }
        window.location.href = value.href
        // navigator(value.href)
    }
    return (
        <Layout>
            <div className='bg-[#131620]  overflow-x-hidden !h-[100vh] pt-[34px] pr-[20px] pb-[34px] pl-[20px] text-center flex flex-col items-center relative'>
                <HeadNavi
                    tournaments={tournaments}
                    setTournaments={handelChooseTournaments}
                />
                <div className="w-full h-full flex justify-center items-center ">
                    <div className="w-[1375px] h-full flex justify-end">
                        <div className=" flex flex-col items-center justify-end  ">
                            <div className="flex items-center w-[1375px] bg-gradient-A98F34 px-6 border-r border-r-[#202530] border-l border-l-[#202530] border-t border-t-[#202530]">
                                <span className={`text-[18px] ] font-medium py-2 pr-12 text-[#E4E7F1] `}>
                                    STATEMENT
                                </span>
                            </div>
                            <div className="w-full ">
                                <div className="w-full flex items-center justify-center py-6 border-r border-r-[#202530] border-l border-l-[#202530]">
                                    <div className="flex items-center gap-2 border-r border-r-[#212632]"
                                        onClick={() => setChooseGame(1)}
                                    >
                                        <img src={`${chooseGame === 1 ? soccerActice : soccer}`} alt="" />
                                        <span
                                            className={`uppercase text-sm  pr-[51px] ${chooseGame === 1 ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}
                                        >
                                            soccer
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-2 border-r border-r-[#212632]"
                                        onClick={() => setChooseGame(2)}
                                    >
                                        <img src={`${chooseGame === 2 ? baketballActive : baketball}`} alt="" className="pl-[51px]" />
                                        <span className={`uppercase text-sm  pr-[51px] ${chooseGame === 2 ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>nba</span>
                                    </div>
                                    <div className="flex items-center gap-2 border-r border-r-[#212632]"
                                        onClick={() => setChooseGame(3)}
                                    >
                                        <img src={`${chooseGame === 3 ? rugbyActive : rugby}`} alt="" className="pl-[51px]" />
                                        <span className={`uppercase text-sm  pr-[51px] ${chooseGame === 3 ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>nfl</span>
                                    </div>
                                    <div className="flex items-center gap-2 "
                                        onClick={() => setChooseGame(4)}
                                    >
                                        <img src={`${chooseGame === 4 ? baseballActive : baseball}`} alt="" className="pl-[51px]" />
                                        <span className={`uppercase text-sm   ${chooseGame === 4 ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>mlb</span>
                                    </div>
                                </div>
                                {
                                    chooseGame === 1 && (<SoccerStatement />)
                                }
                                {
                                    chooseGame === 2 && (<NBAStatement />)
                                }
                                {
                                    chooseGame === 3 && (<NFLStatement />)
                                }
                                {
                                    chooseGame === 4 && (<MLBStatement />)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}