import { HeaderTabNew } from "../components/HeaderTabNew";

interface LayoutProps {
    children?: React.ReactNode;
}
export const Layout: React.FC<LayoutProps> = ({
    children
}) => {
    return (
        <div className="flex flex-col items-center w-full overflow-hidden">
            <HeaderTabNew />
            {children}
        </div>
    )
}