/* eslint-disable react-hooks/exhaustive-deps */
import Dropdown from "antd/es/dropdown/dropdown"
import { user_setting, edit, lock_open } from "../imgExport"
import { MenuProps } from "antd";
import './style.css';
import { useEffect, useState } from "react";
import { ModalChangePassWord } from "./Modal/ModalChangePassWord";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { ModalChangeUserName } from "./Modal/ModalChangeUserName";
import { useNavigate } from "react-router-dom";
import * as loginApi from '../../api/login/loginApi';
import useLoading from '../../hook/useLoading';
import useToast from '../../hook/useToast';
import { useDispatch } from 'react-redux';
import { clearAlert } from "../../store/slices/toastSlice";
export const HeaderTabNew: React.FC = () => {
    const navigate = useNavigate()
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    const [username, setUsername] = useState<string>("Adam")
    const [chooseTabMybet, setChooseTabMybet] = useState<boolean>(false)
    const [chooseTabStatement, setChooseTabStatement] = useState<boolean>(false)
    const [openModalChangePass, setOpenModalChangePass] = useState<boolean>(false)
    const [openModalChangeUserName, setOpenModalChangeUserName] = useState<boolean>(false)
    const items: MenuProps['items'] = [
        {
            key: '1',
            type: 'group',
            label: <span className="text-sm text-[#C1C9E1]">Setting</span>,
            children: [
                {
                    key: '1-1',
                    label: <span
                        className="text-sm text-[#C1C9E1] flex items-center gap-3 pb-3"
                        onClick={() => setOpenModalChangeUserName(true)}
                    >
                        <img src={edit} alt="" />
                        Change username
                    </span>,
                },
                {
                    key: '1-2',
                    label: <span
                        className="text-sm text-[#C1C9E1] flex items-center gap-3"
                        onClick={() => setOpenModalChangePass(true)}
                    >
                        <img src={lock_open} alt="" />
                        Change password
                    </span>,
                },
            ],
        },
    ];

    const getUserInformation = async () => {
        pushLoading(true)
        const ketQua = await loginApi.getUserInformation()
        if (ketQua.success) {
            setUsername(ketQua.data.userName)
        } else {
            dispatch(clearAlert())
            pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
            pushLoading(false)
            return
        }
        pushLoading(false)
    }

    const handleChooseTabMybet = () => {
        setChooseTabMybet(true);
        navigate('/mybet');
    };
    const handleChooseTabStatement = () => {
        setChooseTabStatement(true);
        navigate('/statement');
    };

    useEffect(() => {
        getUserInformation()
    }, [])

    return (
        <div className='xs:hidden sm:flex justify-center w-full border-b border-b-[#202531] fixed z-10 bg-[#131620]'>
            <ModalChangePassWord
                isOpen={openModalChangePass}
                closeModal={() => setOpenModalChangePass(false)}
            />
            <ModalChangeUserName
                isOpen={openModalChangeUserName}
                closeModal={() => setOpenModalChangeUserName(false)}
            />
            <div className='border-r border-r-[#202531] h-[39px] flex justify-center items-center'>
                <span className='text-sm text-[#C1C9E1] uppercase font-medium pr-[50px]'>hi, {username}</span>
            </div>
            <div className='border-r border-r-[#202531] h-[39px] flex justify-center items-center'>
                <div className='flex items-center gap-2 px-[50px]'
                    onClick={handleChooseTabMybet}
                >
                    <BookmarkIcon className={` text-[24px] ${chooseTabMybet ? ' text-[#D4B962]' : ' text-[#C1C9E1]'}`} />
                    <span className={`text-sm uppercase font-medium ${chooseTabMybet ? ' text-[#D4B962]' : ' text-[#C1C9E1]'}`}>
                        my bet
                    </span>
                </div>
            </div>
            <div className='border-r border-r-[#202531] h-[39px] flex justify-center items-center'>
                <div className='flex items-center gap-2 px-[50px]'
                    onClick={handleChooseTabStatement}
                >
                    <NotificationsActiveIcon className={` text-[24px] ${chooseTabStatement ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`} />
                    <span className={`text-sm uppercase font-medium ${chooseTabStatement ? 'text-[#D4B962]' : 'text-[#C1C9E1]'}`}>
                        Statement
                    </span>
                </div>
            </div>
            <Dropdown menu={{ items }} placement="bottomLeft" arrow={{ pointAtCenter: true }} >
                <div className='border-r border-r-[#202531] h-[39px] flex justify-center items-center'>
                    <div className='flex items-center gap-2 px-[50px]'>
                        <img src={user_setting} alt="" />
                        <span className='text-sm text-[#C1C9E1] uppercase font-medium '>setting</span>
                    </div>
                </div>
            </Dropdown>

        </div>
    )
}