import { Modal } from "antd"
import { iconToastSuccess } from "../../../../components/imgExport";

interface ModalBetsProps {
    keyBetTabs?: string
    showModalBets: boolean,
    totalAmount: number,
    totalAmountParlay?: number
    totalAmountTeaser?: number

    handelBet: () => void
    setShowModalBets: (value: boolean) => void
}
const ModalBets = ({
    keyBetTabs, showModalBets, totalAmount, totalAmountParlay, totalAmountTeaser,
    setShowModalBets, handelBet }: ModalBetsProps) => {

    return (
        <>
            <Modal
                className='canPlayModal flex justify-center modalBets'
                title={<img src={iconToastSuccess} alt="" />}
                // title={<CheckCircleOutlined className='size-9 pb-3' />}
                open={showModalBets}
                onOk={() => handelBet()}
                onCancel={() => setShowModalBets(false)}
                okText={<span className="text-[14px] font-[600] bg-[#D4B962] text-black">Print invoice</span>}
                cancelText={<span className="text-[14px] font-[600]">Cancel</span>}
            >
                <p>You have <span className="text-[#D4B962]">${keyBetTabs === "1" ? totalAmount : keyBetTabs === "2" ? totalAmountParlay : totalAmountTeaser}</span> available amount, you can pay.</p>
            </Modal >

        </>
    )
}
export default ModalBets