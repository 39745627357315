/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import { Form, Input } from "antd";
import { logoKB_2, KB_best_sport_img_2, contact, mail_box } from "../../../components/imgExport";
import '../style.css'
import tokenService from '../../../services/token';
import useLoading from '../../../hook/useLoading';
import useToast from '../../../hook/useToast';
import { useDispatch } from 'react-redux';
import { clearAlert } from '../../../store/slices/toastSlice';
import ToastGlobal from "../../../components/ToastGlobal";
import * as LoginApi from '../../../api/login/loginApi';
import { useEffect, useState } from "react";
import useThrottle from "../../../hook/useThrottle";

var emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function isEmailValid(email: any) {
    if (!email)
        return false;

    if (email.length > 254)
        return false;

    var valid = emailRegex.test(email);
    if (!valid)
        return false;

    // Further checking of some things regex can't handle
    var parts = email.split("@");
    if (parts[0].length > 64)
        return false;

    var domainParts = parts[1].split(".");
    if (domainParts.some(function (part: any) { return part.length > 63; }))
        return false;

    return true;
}

export const ForgotByEmail: React.FC = () => {
    const pushToast = useToast();
    const pushLoading = useLoading();
    const dispatch = useDispatch();
    const [emailHide, setMailHide] = useState<string | null>("Kingbet****12@gmail.com")
    const [code, setCode] = useState<string | null>("123456")

    const onFinish = useThrottle(async (values: any) => {
        if (!isEmailValid(values.email)) {
            dispatch(clearAlert())
            pushToast("Email is not in correct format", "warn");
            pushLoading(false)
            return
        }
        if (values.email) {
            const ketQua = await LoginApi.forgotPasswordConfirmEmail(values.email, code ? code : "")
            if (ketQua?.success) {
                dispatch(clearAlert())
                pushToast("The system has sent a new password to your email. Please get the new password to log in.", "success", 5000);
                pushLoading(false)
                setTimeout(() => {
                    tokenService.removeCode()
                    tokenService.removeEmail()
                    window.location.href = "/login"
                }, 5000)
                return
                // tokenService.setToken(ketQua?.data?.token)
                // window.location.href = "/Soccer_V1"
            } else {
                dispatch(clearAlert())
                pushToast(ketQua?.message ? ketQua?.message : "Have something wrong, Please try later", "warn");
                pushLoading(false)
                return
            }
        } else {
            dispatch(clearAlert())
            pushToast("Please enter email", "warn");
            pushLoading(false)
            return
        }
    }, 5000)

    useEffect(() => {
        setCode(tokenService.getCodeConfirm())
        setMailHide(tokenService.getEmailComfirm())
    }, [])

    return (
        <div className='h-[100vh] !w-full flex  !justify-center !items-center bg-login-new'>
            <div className='flex flex-col xs:w-[343px] xs:gap-3 sm:w-[343px] sm:gap-3 md:w-[694px] md:gap-4 lg:w-[694px] lg:gap-4 items-center rounded-[24px] border border-[#454449] bg-[#14171F]'>
                <div className='flex flex-col items-center xs:gap-3 sm:gap-3 md:gap-6 lg:gap-6 '>
                    <div className="xs:pt-[24px] sm:pt-[24px] md:pt-[44px] lg:pt-[44px]">
                        <img src={logoKB_2} alt="" className="xs:w-[60px] xs:h-[60px] sm:w-[60px] sm:h-[60px] md:w-[120px] md:h-[120px] lg:w-[120px] lg:h-[120px]" />
                    </div>
                    <img src={KB_best_sport_img_2} alt="" />
                    <div className="flex flex-col items-center gap-3">
                        <span className="xs:text-xs sm:text-xs md:text-lg lg:text-lg text-[#E4E7F1] uppercase">please confirm your email address</span>
                        <span className="xs:text-xs sm:text-xs md:text-sm lg:text-sm text-[#E4E7F1] uppercase">Hint: {emailHide}</span>
                    </div>
                </div>
                <ToastGlobal />

                <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    className="xs:w-[279px] sm:w-[279px] md:w-[356px] lg:w-[356px]"
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                        className="custom-input rounded-lg border-4B5563"
                    >
                        <Input
                            prefix={<img src={mail_box} className="xs:w-[12px] xs:h-[12px] sm:w-[12px] sm:h-[12px] md:w-[24px] md:h-[24px] lg:w-[24px] lg:h-[24px]" />}
                            placeholder='Your email'
                            size="large"
                            className="!text-[#C1C9E1] placehoder-C1C9E1"
                        />
                    </Form.Item>
                    <div className="flex flex-col gap-6 items-center w-full xs:pb-0 sm:pb-0 md:pb-6 lg:pb-6">
                        <button className="xs:text-xs  sm:text-xs md:text-sm lg:text-sm bg-[#E1C467] text-[#131620]  rounded-lg xs:py-3 sm:py-3 md:py-[14px] lg:py-[14px] w-full font-bold uppercase" type="submit">
                            confirm
                        </button>
                    </div>
                </Form>
                <div className="flex gap-[14px] xs:pb-6 sm:pb-6 md:pb-[59px] lg:pb-[59px]">
                    <img src={contact} alt="" />
                    <div className="flex flex-col">
                        <span className="uppercase text-sm text-[#FFFFFF] fw-[500]">Need support?</span>
                        <Link to={'/'} className="text-[12px] text-[#D4B962] uppercase">contact us</Link>
                    </div>
                </div>
            </div>

        </div>
    )
}
